import { build } from './build-info';
import { IAppEnvironment } from '@domain/environment';

export const environment: IAppEnvironment = {
    build,
    stage: 'sandbox',
    production: true,
    sandbox: true,
    clientId: 'StudioJS',
    newrelic: {
        enabled: true,
        accountId: '4232543',
        trustKey: '4122654',
        agentId: '538557827',
        licenseKey: 'NRJS-d0b27a9b958bc4b281c',
        applicationId: '538557827'
    },
    appInsights: {
        instrumentationKey: 'a88b7aba-b08e-4416-891e-a3ee95c1977e',
        samplingPercentage: 50,
        enabled: true
    },
    signalR: {
        enabled: true,
        url: 'https://sandbox-api.bannerflow.com/studio/hub'
    },
    intercomId: 'vc7jxmzv',
    gtmId: 'GTM-M2FZ5ZZ',
    origins: {
        acg: 'https://sandbox-api.bannerflow.net/acg',
        analyticsFrontendUrl: 'https://sandbox-analytics.bannerflow.com',
        b2: 'https://sandbox-app.bannerflow.com',
        bannerflowLibrary: 'https://sandbox-api.bannerflow.com/bannerflow-library',
        bannerlingo: 'https://sandbox-api.bannerflow.com/bls',
        bfc: 'https://sandbox-home.bannerflow.com',
        brandManager: 'https://sandbox-api.bannerflow.com/brand-manager',
        campaignManager: 'https://sandbox-cm.bannerflow.com',
        campaignService: 'https://sandbox-api.bannerflow.com/cs',
        commentService: 'https://sandbox-api.bannerflow.com/comment-service',
        cps: 'https://sandbox-api.bannerflow.com/preview',
        feedStorage: 'https://c.sandbox-bannerflow.net/sfeeds',
        fontManager: 'https://sandbox-fontmanager.bannerflow.com',
        fontManagerApi: 'https://sandbox-api.bannerflow.com/font-manager',
        fontService: 'https://c.sandbox-bannerflow.net/fs',
        fontStorage: 'https://fonts.sandbox-bannerflow.net',
        genAiImage: 'https://sandbox-api.bannerflow.com/gen-ai-image-service',
        idp: 'https://sandbox-idp.bannerflow.com',
        imageOptimizer: 'https://c.sandbox-bannerflow.net/io',
        listService: 'https://sandbox-api.bannerflow.com/list-service',
        sapi: 'https://sandbox-api.bannerflow.com/studio/api',
        designApi: 'https://sandbox-api.bannerflow.com/design-api/api',
        socialCampaignManager: 'https://sandbox-scm.bannerflow.com',
        socialCampaignService: 'https://sandbox-scm.bannerflow.com',
        studioBlobStorage: 'https://c.sandbox-bannerflow.net',
        videoStorage: 'https://c.sandbox-bannerflow.net'
    },
    commentServiceCreativeToken: '71be6767-4c5d-4af5-b1bb-d1ff0828fb95',
    commentServiceCreativesetToken: 'b821e067-625c-4db5-bee2-61a695398fab'
};
